body {
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//search box - suggestion
.autosuggest__container {
  position: relative;
  display: inline-block;
  width: 100%;
  input {
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-bottom-color: rgb(219, 219, 219);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-image-outset: 0px;
    border-image-repeat: stretch;
    border-image-slice: 100%;
    border-image-source: none;
    border-image-width: 1;
    border-left-color: rgb(219, 219, 219);
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: rgb(219, 219, 219);
    border-right-style: solid;
    border-right-width: 1px;
    border-top-color: rgb(219, 219, 219);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top-style: solid;
    border-top-width: 1px;
    box-shadow: rgba(10, 10, 10, 0.1) 0px 1px 2px 0px inset;
    box-sizing: border-box;
    color: rgb(54, 54, 54);
    cursor: text;
    display: inline-flex;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-stretch: 100%;
    font-style: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-weight: 400;
    height: 36px;
    justify-content: flex-start;
    letter-spacing: normal;
    line-height: 24px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    max-width: 100%;
    padding-bottom: 5px;
    padding-left: 9px;
    padding-right: 9px;
    padding-top: 5px;
    position: relative;
    text-align: start;
    text-indent: 0px;
    text-rendering: auto;
    text-shadow: none;
    text-size-adjust: 100%;
    text-transform: none;
    vertical-align: top;
    width: 100%;
    word-spacing: 0px;
    writing-mode: horizontal-tb;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    -webkit-rtl-ordering: logical;
    -webkit-border-image: none;
  }

  .ui.inline.loader.active,
  .ui.inline.loader.visible {
    position: absolute;
    right: 7px;
    left: inherit;
    top: 11px;
    display: none;
  }

  &.isLoading {
    .ui.inline.loader.active,
    .ui.inline.loader.visible {
      display: inline;
    }
  }
}

.react-autosuggest__container {
  width: 100%;
  position: relative;
  display: inline-block;
}

.react-autosuggest__input {
  width: 100%;
  height: 30px;
  padding: 10px 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  width: 750px;
  -webkit-align-items: center;
  align-items: center;
  background-color: white;
  box-shadow: rgba(10, 10, 10, 0.1) 0px 1px 2px 0px inset;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  width: 750px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0 !important;
  padding: 0 !important;
  list-style-type: none !important;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 2.5px 1em;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

/*DIMMER*/

.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.38);
}

/* select ellipsis hack */

.ellipsisText {
  & > .text {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ui.search.dropdown > .text {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
